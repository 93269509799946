@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;500;700&family=Poppins:wght@400;500;700&display=swap');

@layer base {
  html {
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 300;
    background-color: #fefffb;
  }
}

h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 1024px;
  margin: 0 auto
}

.container.narrow {
  max-width: 960px;
  margin: 0 auto
}

.sliderContainer {
  overflow: hidden;
  width: auto;
}

.featSlider .slick-track {
  display: flex;
  align-items: center;
  min-height: 245px;
}

.featSlider .caption {
  display: none;
  padding: 0 2rem;
}

.featSlider .slick-slide {
  width: 140px;
}

.featSlider .slick-active {
  width: 480px;
}

.featSlider .element {
  opacity: .5;
  transition: .5s;
}

.featSlider .element:hover {
  opacity: 1;
}

.featSlider .slick-active .element {
  opacity: 1;
}

.featSlider .slick-active .element > div {
  display: flex;
}

.featSlider .slick-active .caption {
  opacity: 1;
  height: auto;
  display: block;
}

.featSlider .slick-active .thumb {
  position: relative;
}

.featSlider .thumb img {
  max-width: 100px;
  transition: .5s;
}

.featSlider .slick-active .thumb img {
  max-width: 200px;
}

.featSlider img {
  max-width: 100%;
}

.on {
  position: absolute;
  width: 4rem;
  right: 0;
  top: 0rem;
  opacity: 0;
}

.featSlider .slick-active .on {
  opacity: 1;
}

input:focus-visible,
input:focus,
input:hover {
  border-color: #00aac8;
}

input,
textarea {
  outline: none;
}

.howSliderWrap {
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

/* Customize the label (the container) */
.check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: none;
  border: 2px solid #00aac8;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.check:hover input ~ .checkmark:after {
  border-color: #00aac8;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark:after {
  border-color: #00aac8;
  content: "";
  position: absolute;
  display: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 15px;
  border: solid transparent;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#foodGallery.item01 #item02 p,
#foodGallery.item01 #item03 p {
  display: none;
}

#foodGallery.item02 #item01 p,
#foodGallery.item02 #item03 p {
  display: none;
}

#foodGallery.item03 #item01 p,
#foodGallery.item03 #item02 p {
  display: none;
}

#foodGallery.item01 #item01 .text-ffbluebright {
  color: #f83f76;
}

#foodGallery.item01 #item01 .text-ffbluebright:before {
  background-color: #f83f76;
}

#foodGallery.item02 #item02 .text-ffbluebright {
  color: #f83f76;
}

#foodGallery.item02 #item02 .text-ffbluebright:before {
  background-color: #f83f76;
}

#foodGallery.item03 #item03 .text-ffbluebright {
  color: #f83f76;
}

#foodGallery.item03 #item03 .text-ffbluebright:before {
  background-color: #f83f76;
}

#foodGallery .photo01,
#foodGallery .photo02,
#foodGallery .photo03 {
  transition: .5s;
}

#foodGallery .photo02,
#foodGallery .photo03 {
  left: -200px;
  opacity: 0;
}

#foodGallery.item02 .photo02,
#foodGallery.item03 .photo02 {
  left: -20px;
  opacity: 1;
}

#foodGallery.item03 .photo03 {
  left: 20px;
  opacity: 1;
}

.video-js {
  background-color: transparent !important;
}

.cta:link,
.cta:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #f83f76;
  background-image: linear-gradient(90deg, #f83f76, #f83f76);
  color: #fff;
  border: none;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.cta::before {
  background: #fff;
  content: "";
  height: 155px;
  opacity: 0;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  width: 50px;
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  display: none;
}

.cta::after {
  background: #fff;
  content: "";
  height: 40rem;
  opacity: 0;
  position: absolute;
  top: -100px;
  transform: rotate(35deg);
  transition: all 3000ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 8rem;
  display: none;
}

.cta::before {
  left: -50%;
  top: -1rem;
}

.cta::after {
  left: -100%;
}

.cta:hover,
.cta:active {
  transform: translateY(-3px);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.cta:hover::before {
  left: 150%;
  opacity: 0.5s;
}

.cta:hover::after {
  left: 200%;
  opacity: 0.6;
}

.cta span {
  z-index: 20;
}

.slick-next,
.slick-next:focus,
.slick-next:hover {
  right: 20px;
  background-image: url('images/right.svg');
}

.slick-prev,
.slick-prev:focus,
.slick-prev:hover {
  left: 20px;
  background-image: url('images/left.svg');
}

.howSlider .slick-next,
.howSlider .slick-next:focus,
.howSlider .slick-next:hover {
  right: 20px;
  background-image: url('images/right-white.svg');
}

.howSlider .slick-prev,
.howSlider .slick-prev:focus,
.howSlider .slick-prev:hover {
  left: 20px;
  background-image: url('images/left-white.svg');
}

.howSlider .slick-arrow {
  z-index: 50;
}

.slick-next, .slick-prev {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.slick-next:before, .slick-prev:before {
  color: transparent;
}

#come-funziona .cta {
  background-color: #00aac8;
  background-image: linear-gradient(90deg, #00aac8, #00aac8);
  color: #fff;
}

#come-funziona .cta::before,
#come-funziona .cta::after {
  background: #fff;
}

.lastRow .ctaSec {
  background: #8dcfd4;
  background-image: linear-gradient(90deg, #8dcfd4, #8dcfd4);
}

form .cta,
#foodGallery .cta {
  background-color: #f83f76;
  background-image: linear-gradient(90deg, #f83f76, #f83f76);
  color: #fff;
}

form .cta:hover,
#foodGallery .cta:hover {
  color: #fff;
}

form .cta::before,
form .cta::after,
#foodGallery .cta::before,
#foodGallery .cta::after {
  background: #fff;
}


#moveSlides ul {
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}

#moveSlides ul li {
  display: inline-block;
  width: 16.666%;
  height: 600px;
  opacity: 0.75;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer;
}

#moveSlides ul li .box {
  position: absolute;
  top: 3rem;
  left: 2rem;
  color: #fff;
  width: 75%;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#moveSlides ul li .box h3 {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
}

#moveSlides ul li .box p {
  min-width: 300px;
  opacity: .5;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#moveSlides ul li .box .btn {
  border-radius: 60px;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  text-align: center;
  padding: .5rem 2rem;
  display: inline-block;
  margin-top: 1rem;
  font-weight: bold;
  cursor: pointer;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#moveSlides ul li .box img {
  filter: invert();
  filter: grayscale(100%);
}

#moveSlides ul li:hover, ul li:first-child ~ li:last-child {
  width: 50%;
  opacity: 1;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#moveSlides ul li:hover .box p {
  opacity: 1;
}

#moveSlides ul li:hover .box .btn {
  background: #f83f76;
  color: #fff;
  border-color: #f83f76;
}

#moveSlides ul li:hover ~ li:last-child {
  width: 16.666%;
  opacity: 0.4;
}
#moveSlides ul li:nth-child(1) {
  background: url("images/bckg1.jpg") top center no-repeat;
}
#moveSlides ul li:nth-child(2) {
  background: url("images/bckg2.jpg") top right no-repeat;
}
#moveSlides ul li:nth-child(3) {
  background: url("images/bckg3.jpg") top center no-repeat;
}
#moveSlides ul li:nth-child(4) {
  background: url("images/bckg4.jpg") top center no-repeat;
}

#moveSlides ul li::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#moveSlides ul li:nth-child(1)::before {
  background-color: rgba(255, 87, 34, 0.5);
}
#moveSlides ul li:nth-child(2)::before {
  background-color: rgba(76, 175, 80, 0.5);
}
#moveSlides ul li:nth-child(3)::before {
  background-color: rgba(33, 150, 243, 0.5);
}
#moveSlides ul li:nth-child(4)::before {
  background-color: rgba(156, 39, 176, 0.5);
}


#moveSlides .panels.open {
  position: absolute;
  top: 0;
  left: 0;
}

#moveSlides .panels.open li,
#moveSlides .panels.open li:hover,
#moveSlides .panels.open li:hover ~ li:last-child {
  height: 140px;
  width: 160px;
  display: block;
  opacity: 1;
}

#moveSlides .panels.open .box {
  top: 1rem;
  left: 1rem;
}

#moveSlides .panels.open .box p,
#moveSlides .panels.open .box .btn {
  display: none;
}

#moveSlides .panels.open li .box img {
  width: 48px;
}

#moveSlides .panels.open .box h3 {
  font-size: 1.25rem;
}

#moveSlides .wrap {
  position: relative;
}

#moveSlides .content {
  height: 0;
  overflow: hidden;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#moveSlides .content.open {
  top: 0;
  left: 210px;
  height: auto;
  width: calc(100% - 160px);
  margin-left: 160px;
}

.contentBlock > div {
  color: #fff;
}

.contentBlock .container {
  padding: 2rem;
}

.contentBlock > div h3 {
  font-size: 3rem;
  font-weight: bold;
}

.contentBlock .item-1 {
  background-color: #bc5634;
}

.contentBlock .item-2 {
  background-color: #409476;
}

.contentBlock .item-3 {
  background-color: #2768a3;
}

.contentBlock .item-4 {
  background-color: #5b318d;
}


#boxes {
  color: rgb(0,170,200);
  font-weight: bold;
}

#boxes .firstRow {
  display: grid;
  grid-template-columns: 50% 50%;
}

.boxItem {
  transition: background-color .25s ease;
  position: relative;
  height: 100%;
  min-height: 410px;
  cursor: pointer;
}

.boxItem .wrap {
  position: absolute;
  padding-left: 3rem;
  padding-bottom: 3rem;
  bottom: 0;
  width: calc(100% - 3rem);
}

.boxItem .ico {
  bottom: 0;
  position: relative;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
  transition-delay: .2s;
}

.boxItem h3 {
  bottom: 0;
  position: relative;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
  transition-delay: .1s;
}

.boxItem span {
  bottom: 0;
  position: relative;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
}

.boxItem .underHover {
  position: absolute;
  font-weight: 300;
  margin-top: 1rem;
  opacity: 0;
  bottom: 0;
  padding-bottom: 3rem;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
}

.boxItem .underHover .arrowRight {
  max-width: 2rem;
}

.boxItem04 p {
  max-width: 480px;
}


.boxItem:hover .ico {
  position: relative;
  bottom: 125px;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
}

.boxItem:hover h3 {
  position: relative;
  bottom: 130px;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
  transition-delay: .2s;
}

.boxItem:hover span {
  position: relative;
  bottom: 130px;
  transition: bottom .6s cubic-bezier(0.65, 0, 0.35, 1);
  transition-delay: .4s;
}

.boxItem:hover .underHover {
  transition: opacity 1s linear .5s, height 2s linear 0s;
  opacity: 1;
}


.boxItem01 {
  background: rgb(0,170,201,.1);
}

.boxItem01:hover {
  color: #fff;
  background: #00A8C7;
}

.boxItem01:hover .btn {
  color: #00A8C7;
}

.boxItem01:hover .hoverIcon {
  opacity: 1;
}

.boxItem02 {
  background: rgb(0,170,201,.06);
  height: 50%;
}

.boxItem02:hover {
  color: #fff;
  background: #E8477D;
}

.boxItem02:hover .btn {
  color: #E8477D;
}

.boxItem03 {
  background: rgb(0,170,201,.03);
  height: 50%;
}

.boxItem03:hover {
  color: #fff;
  background: #A0D882;
}

.boxItem03:hover .btn {
  color: #A0D882;
}

.boxItem04 {
  background: rgb(0,170,201,.03);
}

.boxItem04:hover {
  color: #00A8C7;
  background: #F8E691;
}

.boxItem04:hover .btn {
  background: #00A8C7;
  color: #fff;
}

#perche .accordion > div:nth-child(2n) {
  background: #e6f8fc;
}

.section-content {
  transition: all 1s;
  display: none;
  animation: anim 1s cubic-bezier(0.65, 0, 0.35, 1);
  transition: opacity 1s cubic-bezier(0.65, 0, 0.35, 1);
}

.section-content.active {
  z-index: 10;
  display: block;
}

#windows.open #boxes {
  display: none;
}

.accordion-content ol li {
  list-style: decimal;
  margin-left: 1rem;
  margin-top: 1rem;
}

@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#footer {
  z-index: 999;
  position: relative;
}

#footer ul li {
  width: 100%;
}

#footer ul li a {
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 8px 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  display: block;
  font-size: 14px;
  transition: .5s;
  line-height: 1.25;
}

#footer ul li:hover a {
  padding-left: 5px;
}

#footer .secNav {
  margin-top: 11px;
}

#footer .secNav a,
#footer .secNav p {
  color: rgba(0, 168, 199, 0.5);
  padding: 0;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  display: inline;
  margin-left: 2rem;
  font-size: .9rem;
}

.endBar {
  justify-content: space-between;
}

.logoFoot {
  margin-top: 8px;
  max-width: 160px;
}

.backTop {
  position: fixed;
  bottom: 2rem;
  mix-blend-mode: multiply;
  right: 4rem;
  opacity: 0;
  transition: opacity 0.5s ease; /* Dodaj efekt przejścia */
}

.fadeIn {
  opacity: 1 !important; /* Pokaż element, gdy ma klasę fadeIn */
}

#come-funziona .video-js video {
  border-radius: 12px;
}

.photoTeam {
  max-width: 152px;
  margin: 0 auto;
}

.photoGrid > div {
  max-width: 152px;
  margin: 0 auto;
}

.photoGrid3 {
  max-width: 720px;
  margin: 0 auto;;
}

.box .ico {
  min-width: 80px;
  height: 80px;
  background-image: url('images/br01.svg');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 2rem 0 .5rem;
}

.box02 .ico {
  background-image: url('images/br02.svg');
}

.box03 .ico {
  background-image: url('images/br03.svg');
}

.box04 .ico {
  background-image: url('images/br04.svg');
}

.box01:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -12px;
  left: 0;
  display: block;
  border-bottom: 1px solid rgb(0 170 200);
}

.box01:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  right: -12px;
  top: 0;
  display: block;
  border-right: 1px solid rgb(0 170 200);
}

.box02:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: -12px;
  left: 0;
  display: block;
  border-bottom: 1px solid rgb(0 170 200);
}

.box03:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  right: -12px;
  top: 0;
  display: block;
  border-right: 1px solid rgb(0 170 200);
}

.box:hover h3,
.box:hover p {
  color: #fff;
}

.box01:hover .ico {
  background-image: url('images/br01h.svg');
}

.box02:hover .ico {
  background-image: url('images/br02h.svg');
}

.box03:hover .ico {
  background-image: url('images/br03h.svg');
}

.box04:hover .ico {
  background-image: url('images/br04h.svg');
}

.box p {
  opacity: 0;
}

.box:hover p {
  opacity: 1;
}

#Green {
  background-image: url('images/leaf.svg');
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 15%;
  width: 965px;
  height: 620px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 999;
  display: none;
}

.close-button {
  background-image: url('images/close-button.svg');
  width: 42px;
  height: 42px;
  position: absolute;
  left: 13rem;
  top: 4.5rem;
  cursor: pointer;
  background-size: 100% auto;
  border-radius: 50px;
  box-shadow: 2px 2px 4px 2px rgba(0,0,0,.015);
  transition: .5s;
}

.close-button:hover {
  box-shadow: 2px 2px 4px 2px rgba(0,0,0,.15);
}

#Green .wrap {
  width: 550px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  transform: translate(0, -50%);
  font-size: 1.15rem;
  padding-left: 3rem;
}

#Green .tag {
  background-image: url('images/icon-leaf.svg');
  background-repeat: no-repeat;
  background-position: 1.25rem center;
  background-size: 18px auto;
  background-color: #84c872;
  padding: .5rem 1rem .5rem 3rem;
  border-radius: 60px;
  display: inline-block;
  margin-bottom: 1rem;
}

#Green p {
  margin-bottom: 2rem;
}

#Green li {
  background-image: url('images/bullet-point-1.svg');
  background-repeat: no-repeat;
  background-size: 22px auto;
  background-position: left 6px;
  padding-left: 2rem;
  margin-bottom: 1rem;
}

#greenBtn {
  position: fixed;
  top: 6.5rem;
  right: 5rem;
  cursor: pointer;
  width: 2.5rem;
  display: none;
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}


@media only screen and (max-width: 768px) {

  .featSlider {
    padding: 3rem;
  }

  #featuresSlider .active img {
    max-width: 10rem;
  }

  #featuresSlider .caption {
    opacity: 1;
    width: auto;
    height: auto;
    transform: scale(1);
  }

  #featuresSlider .element {
    opacity: 1;
    padding: 2rem 0;
  }

  .on {
    width: 3rem;
    right: 0;
    top: 2rem;
    opacity: 1;
  }

  .featSlider .caption {
    display: block;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .featSlider .slick-active .thumb img {
    max-width: 100px;
    margin-right: 1rem;
  }

  .slick-next, .slick-prev {
    width: 24px;
    height: 24px;
  }

  .featSlider .slick-active .thumb {
    margin-right: 0;
  }

  .featSlider .slick-track {
    min-height: 0;
  }

  .sliderContainer {
    width: 100%;
  }

  .on {
    top: 0;
  }

  .howSlider .slick-prev, .howSlider .slick-prev:focus, .howSlider .slick-prev:hover {
    left: 0;
  }

  .howSlider .slick-next, .howSlider .slick-next:focus, .howSlider .slick-next:hover {
    right: 0px;
  }

  .howSlider .slick-arrow {
    top: 42.5%;
  }

  #foodGallery .photo02, #foodGallery .photo03 {
    opacity: 1;
  }

  #foodGallery.item01 #item02 p,
  #foodGallery.item01 #item03 p,
  #foodGallery.item02 #item01 p,
  #foodGallery.item02 #item03 p,
  #foodGallery.item03 #item01 p,
  #foodGallery.item03 #item02 p {
    display: block;
  }

  #foodGallery.item01 #item01 .text-ffbluebright
  #foodGallery.item02 #item02 .text-ffbluebright,
  #foodGallery.item03 #item03 .text-ffbluebright {
    color: #f83f76;
  }

  #foodGallery .text-ffbluebright {
    color: #f83f76;
  }

  #foodGallery.item01 #item01 .text-ffbluebright:before,
  #foodGallery.item01 #item02 .text-ffbluebright:before,
  #foodGallery.item01 #item03 .text-ffbluebright:before,
  #foodGallery.item02 #item01 .text-ffbluebright:before,
  #foodGallery.item02 #item02 .text-ffbluebright:before,
  #foodGallery.item02 #item03 .text-ffbluebright:before,
  #foodGallery.item03 #item01 .text-ffbluebright:before,
  #foodGallery.item03 #item02 .text-ffbluebright:before,
  #foodGallery.item03 #item03 .text-ffbluebright:before {
    background-color: #f83f76;
  }

  #header .cta {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .featSlider .slick-track {
    transform: translate3d(-565px, 0px, 0px) !important;
  }
}

@media only screen and (max-width: 1370px) {
  #header .items-center a {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 20px;
    padding: 0;
  }
  #header .items-center a.btnDemo {
    display: inline-block;
    padding: .5rem 1rem;
  }

  #header .items-center a {
    margin-left: 15px;
  }


  #boxes .firstRow {
    display: block;
  }

  .boxItem {
    min-height: 320px;
  }

  .boxItem01 {
    background: #00A8C7;
    color: #fff;
  }

  .boxItem01 .ico img {
    display: none;
  }

  .boxItem01 .ico img.hoverIcon {
    display: block;
    opacity: 1;
    position: static;
  }

  .boxItem02 {
    background: #E8477D;
    color: #fff;
  }

  .boxItem03 {
    background: #A0D882;
    color: #fff;
  }

  .boxItem04 {
    color: #00A8C7;
    background: #F8E691;
  }

  .boxItem .wrap {
    position: static;
    padding: 3rem;
  }

  .boxItem .ico {
    width: 3rem;
  }

  .boxItem h3 {
    font-size: 2rem;
    padding-bottom: 0;
    margin: 0;
  }

  .boxItem span {
    font-size: 14px;
  }

  .boxItem .underHover {
    position: static;
    opacity: 1;
    padding: 0;
  }

  .boxItem .underHover p {
    font-size: 14px;
    font-weight: 300;
  }

  .boxItem:hover .ico,
  .boxItem:hover h3,
  .boxItem:hover span {
    position: static;
  }

  .section-content .container {
    padding: 3rem 2rem;
  }

  .section-content #close {
    width: 1.5rem;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 2;
  }

  .section-content .tagLabel {
    display: inline-block;
    padding: 4px 16px 4px 4px;
    font-size: 12px;
  }

  .section-content .tagLabel img {
    width: 1.5rem;
  }

  .section-content h3,
  .section-content h2 {
    font-size: 36px;
    padding-top: 2rem;
  }

  .section-content p {
    width: 100%;
    font-size: 20px;
  }

  .accordion-section {
    padding: 0;
  }

  .accordion-title {
    font-size: 1.75rem;
    line-height: 1.125;
  }

  .accordion-content {
    width: 100%;
  }

  .accordion-section .toggle-button img {
    width: 3rem;
  }

  .accordion-section .toggle-button span {
    font-size: 18px;
  }

  #chi-siamo,
  #soluzioni {
    padding: 0;
  }

  .countryLabel {
    font-size: 20px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1160px) {
  #header img {
    max-width: 120px;
  }
  #hero .container,
  #frescofrigo .container,
  #come-funziona .container,
  #tecnologia .container,
  #soluzioni .container,
  #auto .container,
  #contatti .container,
  #chi-siamo .container,
  #form .container {
    padding: 0 4rem;
  }
  .lastRow .ctaSec {
    display: inline-block;
  }
  #header .items-center a {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 990px) {
  #header > .flex {
    flex-direction: column;
    align-items: center;
  }
  #header .items-center a {
    font-size: 15px;
    margin-right: 0px;
    margin-left: 10px;
    padding: 0;
  }
  #header img {
    max-width: 100%;
  }
  #form form {
    padding-left: 0;
  }
  #form input,
  #form textarea {
    font-size: 16px;
  }
  p {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 768px) {
  #header .items-center a.btnDemo {
    display: none;
  }

  .logoFoot {
    margin-bottom: 2rem;
  }

  #footer ul {
    margin-bottom: 2rem;
  }

  .backTopStatic {
    display: none;
  }

  #footer .secNav {
    margin: 2rem 0;
  }

  #footer .secNav a,
  #footer .secNav p {
    display: block;
    width: 100%;
    text-align: center;
    margin: 1rem 0 0 0;
  }

  #Brevetto .box {
    display: block;
  }

  #Brevetto .box .ico {
    width: 80px;
    margin-bottom: 1rem;
  }

  #Brevetto .box p {
    opacity: 1;
  }

  #Brevetto .box:before,
  #Brevetto .box:after {
    display: none;
  }

  #Green {
    background-image: url('images/leafMobile.svg');
    width: 90%;
    height: 100%;
    right: auto;
    top: 10rem;
    background-position: top center;
  }

  #Green .wrap {
    width: 80%;
    font-size: 1rem;
    padding: 0;
    top: 45%;
  }

  .close-button {
    top: 2%;
    right: 6%;
    left: auto;
  }

  #greenBtn {
    max-width: 24px;
    top: 3rem;
    right: 1rem;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

    .backTop {
      right: 1rem;
      bottom: 1rem;
    }


}